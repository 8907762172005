<template>
	<header>
		<div class="flex items-center justify-between bg-gray-800 p-8">
			<div class="min-w-0 flex-1">
				<h2 class="font-bold leading-7 text-white truncate text-4xl tracking-tight p-4">Workspaces</h2>
			</div>

			<div class="mt-4 flex items-center ml-4 space-x-3">
				<button type="button" @click="toggleActive" class="inline-flex items-center whitespace-nowrap rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-200/20">
					{{ getToggleText }}
				</button>

				<SearchComponent @search-workspaces="searchWorkspaces"></SearchComponent>
			</div>
		</div>
	</header>

	<div class="grid grid-cols-1 gap-6 sm:grid-cols-2 p-8 shadow-inner border-t">
		<div v-for="workspace in workspaces" :key="workspace.workspace_id" @click="goToPage" class="relative flex items-center space-x-4 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-lg hover:border-gray-400 hover:shadow-xl cursor-pointer">
			<div>
				<ImpersonateDropdown
					@click="(e) => e.stopPropagation()"
					class="h-10 w-10 rounded-full"
					:customerId="workspace.customer_id"
					:src="getIcon(workspace.workspace_id)">
				</ImpersonateDropdown>
			</div>

			<div class="min-w-0 flex-1">
				<p class="text-sm font-medium text-gray-900">{{ workspace.workspace_name }}</p>
				<p class="truncate text-sm text-gray-500">{{ workspace.workspace_id }}</p>
			</div>

			<state-badge :customClass="stateBadgeClass(workspace.subscription_status)">
				<template v-slot:state>{{ workspace.subscription_status }}</template>
			</state-badge>

			<div class="sm:flex space-x-2 hidden">
				<CreditCardIcon class="h-6 w-6 text-gray-600"></CreditCardIcon>
				<ReceiptPercentIcon class="h-6 w-6 text-gray-600"></ReceiptPercentIcon>
				<UserCircleIcon class="h-6 w-6 text-gray-600"></UserCircleIcon>
			</div>

			<div class="flex flex-none items-center gap-x-4">
				<ChevronRightIcon class="h-8 w-8 text-gray-900"></ChevronRightIcon>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ChevronRightIcon, CreditCardIcon, ReceiptPercentIcon, UserCircleIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import StateBadge from "../../components/badges/StateBadge.vue";
import ImpersonateDropdown from "../../components/dropdowns/ImpersonateDropdown.vue";
import SearchComponent from "./components/SearchComponent.vue";
import { minidenticon } from "minidenticons";

export default {
	emits: ["search-workspaces", "filter-workspaces"],
	data() {
		return {
			showActiveOnly: false
		};
	},
	props: {
		workspaces: {
			type: Array,
			required: true
		}
	},
	components: {
		StateBadge,
		ImpersonateDropdown,
		SearchComponent
	},
	computed: {
		stateBadgeClass() {
			return (state) => {
				switch (state) {
				case "active":
					return "text-green-700 bg-green-50 ring-green-600/20 py-0.5 px-1.5";
				case "in_trial":
					return "text-yellow-600 bg-yellow-50 ring-yellow-500/50 py-0.5 px-1.5";
				case "cancelled":
					return "text-red-800 bg-red-50 ring-red-600/20 py-0.5 px-1.5";
				default:
					return "text-gray-700 bg-gray-50 ring-gray-600/20 py-0.5 px-1.5";
				}
			};
		},
		getToggleText() {
			if (this.showActiveOnly) {
				return "Show All";
			}

			return "Show Active Only";
		}
	},
	methods: {
		getIcon(workspaceId) {
			const svg = minidenticon(workspaceId, 75, 50);

			return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
		},
		goToPage() {
			this.$router.push({ name: "notFound" });
		},
		searchWorkspaces(searchData) {
			this.$emit("search-workspaces", searchData);

			if (searchData === "") {
				this.showActiveOnly = false;
			}
		},
		toggleActive() {
			this.showActiveOnly = !this.showActiveOnly;

			this.$emit("filter-workspaces", this.showActiveOnly);
		}
	}
};
</script>
