<template>
    <div class="ml-auto pr-12 grid gap-3 sm:inline-block sm:space-x-3 sm:grid-cols-1 sm:gap-0 sm:space-y-0 grid-cols-2">
        <button type="button" @click="refreshAccountData" class="group relative inline-flex items-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-md ring-inset hover:ring-0 ring-gray-300 hover:bg-aimfox-normal hover:text-white focus:z-10">
            <ArrowPathIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-white" aria-hidden="true" />
            Refresh
        </button>

        <a :href="url" target="_blank" class="cursor-pointer group relative inline-flex items-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-md ring-inset hover:ring-0 ring-gray-300 hover:bg-aimfox-normal hover:text-white focus:z-10">
            <font-awesome-icon icon="user-secret" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-white" aria-hidden="true" />
            Impersonate
        </a>

        <button type="button" @click="copyUrl" class="group relative inline-flex items-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-md ring-inset hover:ring-0 ring-gray-300 hover:bg-aimfox-normal hover:text-white focus:z-10">
            <DocumentDuplicateIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-white" aria-hidden="true" />
            Copy URL
        </button>

        <button type="button" @click="copyToken" class="group relative inline-flex items-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-md ring-1 ring-inset hover:ring-0 ring-gray-300 hover:bg-aimfox-normal hover:text-white focus:z-10">
            <KeyIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-white" aria-hidden="true" />
            Copy Token
        </button>
    </div>
</template>

<script setup>
import { DocumentDuplicateIcon, KeyIcon, ArrowPathIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import impersonateService from "../../../services/impersonate";
import accountsService from "../../../services/accounts";
import copy from "copy-to-clipboard";

export default {
	emits: ["show-toast"],
	props: {
		customerId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			url: null
		};
	},
	created() {
		this.getImpersonateUrl();
	},
	methods: {
		getImpersonateUrl: async function () {
			return impersonateService.getUrl(this.customerId)
				.then(response => {
					this.url = response;
				}).catch(() => {
					return this.$emit("show-toast", { type: "UrlError", message: "Error getting url.", status: "error" });
				});
		},

		refreshAccountData: async function () {
			return accountsService.refreshAccountData(this.$route.params.account_id)
				.then(response => {
					this.$emit("show-toast", { type: "Success", message: "Successfully refreshed data.", status: "success" });

					setTimeout(() => {
						window.location.reload();
					}, 2500);
				}).catch(() => {
					return this.$emit("show-toast", { type: "RefreshError", message: "Error refreshing data.", status: "error" });
				});
		},

		copyUrl() {
			if (!this.url) {
				return this.$emit("show-toast", { type: "UrlError", message: "Error copying url.", status: "error" });
			}

			copy(this.url);

			return this.$emit("show-toast", { type: "Success", message: "Successfully copied url.", status: "success" });
		},

		copyToken() {
			if (!this.url) {
				return this.$emit("show-toast", { type: "TokenError", message: "Error copying token.", status: "error" });
			}

			const token = this.url.split("#");

			copy(token[1]);

			return this.$emit("show-toast", { type: "Success", message: "Successfully copied token.", status: "success" });
		}
	}
};
</script>
