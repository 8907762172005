<template>
	<div>
		<SearchComponent @search-customers="searchCustomers" class="font-normal p-2 border-b"></SearchComponent>

		<ul role="list" class="min-w-full divide-y divide-gray-200">
			<li v-for="customer in this.stackedCustomers" :key="customer.id" class="flex items-center justify-between gap-x-6 py-2 px-3 even:bg-gray-100 hover:shadow-inner">
				<div class="min-w-0">
					<div class="flex items-start gap-x-3">
						<p class="text-sm font-semibold leading-6 text-gray-900">{{ customer.full_name }}</p>
					</div>

					<div class="flex flex-col text-xs leading-5 text-gray-500">
						<p class="whitespace-nowrap">{{ customer.email }}</p>

						<p class="truncate">{{ customer.chargebee_id }}</p>
					</div>
				</div>

				<div class="flex flex-none items-center gap-x-4">
					<ImpersonateDropdown
						@copy-url="copyUrl"
						@copy-token="copyToken"
						@edit-customer="emitEdit"
						:customerId="customer.id">
					</ImpersonateDropdown>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import ImpersonateDropdown from "./ImpersonateDropdown.vue";
import SearchComponent from "./SearchComponent.vue";

export default {
	emits: ["copy-url", "copy-token", "search-customers", "edit-customer"],
	props: {
		stackedCustomers: {
			type: Array,
			required: true
		}
	},
	components: {
		ImpersonateDropdown,
		SearchComponent
	},
	methods: {
		copyUrl(url) {
			this.$emit("copy-url", url);
		},

		copyToken(url) {
			this.$emit("copy-token", url);
		},

		emitEdit(customerId) {
			this.$emit("edit-customer", customerId);
		},

		searchCustomers(searchData) {
			this.$emit("search-customers", searchData);
		}
	}
};
</script>
