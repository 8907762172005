const axios = require("axios");
const config = require("../config");

module.exports = {
	list: () => {
		return axios.get(`${config.get("HOST")}/api/v1/workspaces`, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.workspaces;
		});
	}
};
