const axios = require("axios");
const config = require("../config");

module.exports = {
	list: () => {
		return axios.get(config.get("HOST") + "/api/v1/customers", {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.customers.map(customer => {
				return {
					...customer,
					full_name: `${customer.first_name} ${customer.last_name}`
				};
			});
		});
	},

	listActive: () => {
		return axios.get(config.get("HOST") + "/api/v1/customers/active", {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data.customers.map(customer => ({
				...customer,
				full_name: `${customer.first_name} ${customer.last_name}`
			}));
		});
	},

	updateCustomer: (customerId, customerObject) => {
		return axios.patch(config.get("HOST") + `/api/v1/customers/${customerId}`, {
			data: customerObject
		}, {
			headers: {
				"Authorization": "Bearer " + localStorage.getItem("auth")
			}
		}).then(response => {
			return response.data;
		});
	}
};
