<template>
	<header class="bg-gray-100 relative shadow-inner">
		<div class="py-6 px-8">
			<div class="flex flex-col sm:flex-row items-center justify-between gap-y-4 sm:gap-x-8">
				<div class="flex items-center gap-x-6">
					<UserGroupIcon class="h-20 w-20 flex-none text-aimfox-normal" />

					<h1>
						<div class="mt-1 text-3xl font-semibold text-gray-900">Customers</div>
						<div class="text-m leading-6 text-gray-500">
							A detailed list showing relevant information about aimfox customers.
						</div>
					</h1>
				</div>

				<SwitchComponent class="w-full sm:w-auto justify-center" @list-active-customers="filterActiveCustomers" @list-customers="filterCustomers"></SwitchComponent>
			</div>
		</div>
	</header>

	<TableList
		class="hidden md:block"
		:tableCustomers="allCustomers"
		:sortedBy="sortedBy"
		:reversed="reversed"
		@search-customers="searchCustomers"
		@copy-url="copyUrl"
		@copy-token="copyToken"
		@edit-customer="editCustomer">
	</TableList>

	<StackedList
		class="md:hidden"
		:stackedCustomers="allCustomers"
		@search-customers="searchCustomers"
		@copy-url="copyUrl"
		@copy-token="copyToken"
		@edit-customer="editCustomer">
	</StackedList>

	<EditModal
		:show="openEditModal"
		@close-edit-modal="openEditModal = false" @submit-edit="submitEdit">
	</EditModal>
</template>

<script setup>
import { UserGroupIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import copy from "copy-to-clipboard";
import { useToast } from "vue-toastification";
import DefaultToast from "../../components/toasts/DefaultToast.vue";
import SwitchComponent from "./components/SwitchComponent.vue";
import EditModal from "./components/EditModal.vue";
import StackedList from "./components/StackedList.vue";
import TableList from "./components/TableList.vue";

export default {
	emits: ["list-active-customers", "list-customers", "search-customers", "sort-customers", "edit-customer"],
	props: {
		allCustomers: {
			type: Array,
			required: true
		},

		sortedBy: {
			type: String
		},

		reversed: {
			type: Boolean
		}
	},
	components: {
		StackedList,
		TableList,
		SwitchComponent
	},
	data() {
		return {
			openEditModal: false,
			toast: useToast({ timeout: 1500 }),
			customerId: null
		};
	},
	methods: {
		editCustomer(customerId) {
			this.openEditModal = true;

			this.customerId = customerId;
		},

		submitEdit(editText) {
			this.openEditModal = false;

			const customerObject = {
				customer_id: this.customerId,
				email: editText
			};

			this.$emit("edit-customer", customerObject);
		},

		searchCustomers(searchData) {
			this.$emit("search-customers", searchData);
		},

		sortCustomers(sortData) {
			this.$emit("sort-customers", sortData);
		},

		filterActiveCustomers() {
			this.$emit("list-active-customers");
		},

		filterCustomers() {
			this.$emit("list-customers");
		},

		copyUrl(url) {
			copy(url);

			this.showSuccess({ type: "Success", message: "Successfully copied url." });
		},

		copyToken(url) {
			const token = url.split("#");

			copy(token[1]);

			this.showSuccess({ type: "Success", message: "Successfully copied token." });
		},

		showSuccess(errorObject) {
			this.toast.success({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		}
	}
};
</script>
