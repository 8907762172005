<template>
	<Stats
		:accountsLength="accounts.length"
		:metrics="metricsObject"
		:paidAccounts="paidAccounts"
		:activeCampaigns="activeCampaigns"
		:connectedAccounts="connectedAccounts"
		:disconnectedAccounts="disconnectedAccounts">
	</Stats>
	<AccountsComponent
		:accounts="getFiltered(this.currentPage, this.pageSize)"
		@sort-accounts="sortAccounts"
		@search-accounts="searchAccounts"
		:sortedBy="sortedBy" :reversed="reversed"
		@reset-accounts="resetAccounts"
		@show-outreach="listOutreach">
	</AccountsComponent>
	<Footer
		:length="filteredAccounts.length" :pageSize="pageSize"
		:pagesLength="getPagesLength(this.filteredAccounts.length, this.pageSize)"
		@change-page="changePage">
	</Footer>
</template>

<script>
import Stats from "../components/stats/Stats.vue";
import AccountsComponent from "./accounts/AccountsComponent.vue";
import Footer from "../components/footer/Footer.vue";
import redirect from "../services/redirect";
import metrics from "../services/metrics";
import campaigns from "../services/campaigns";
import accounts from "../services/accounts";
import { useToast } from "vue-toastification";
import DefaultToast from "../components/toasts/DefaultToast.vue";

export default {
	components: {
		Stats,
		AccountsComponent,
		Footer
	},
	data() {
		return {
			accounts: [],
			filteredAccounts: [],
			currentPage: 1,
			pageSize: 20,
			sortedBy: null,
			reversed: false,
			metricsObject: {},
			toast: useToast({ timeout: 2000 }),
			paidAccounts: 0,
			activeCampaigns: 0,
			connectedAccounts: 0,
			disconnectedAccounts: 0
		};
	},
	props: {
		timestamp: {
			type: String,
			required: true
		}
	},
	watch: {
		timestamp(newTimestamp) {
			return this.setMetrics(newTimestamp);
		}
	},
	created() {
		return Promise.all([this.getAccounts(), this.countCampaigns()])
			.then(() => {
				return this.setMetrics(this.timestamp);
			});
	},
	methods: {
		listOutreach() {
			return this.filteredAccounts = this.accounts.filter(account => account.outreach);
		},

		resetAccounts() {
			return this.filteredAccounts = this.accounts;
		},

		countCampaigns() {
			return campaigns.countActive().then(count => {
				return this.activeCampaigns = count;
			}).catch(error => {
				this.showError(error.response.data.error);

				console.error(error);
			});
		},

		getAccounts: async function () {
			return accounts.listActive().then(activeAccounts => {
				this.accounts = activeAccounts;
				this.filteredAccounts = activeAccounts;

				this.accounts.forEach(account => {
					if (account.outreach) {
						this.paidAccounts += 1;
					}

					if (account.state === "LoggedIn") {
						this.connectedAccounts += 1;
					}

					if (account.state === "LoggedOut" || account.state === "NeverLoggedIn") {
						this.disconnectedAccounts += 1;
					}
				});
			}).catch(error => {
				this.showError(error.response.data.error);

				if (error.response.status === 401) {
					return redirect.getUrl().then(url => {
						window.location.href = url;
					});
				}

				console.error(error);
			});
		},

		setMetrics(timestamp) {
			return metrics.get(timestamp).then(response => {
				const updatedAccounts = this.accounts.map(account => {
					const accountData = response.metrics.find(element => element.account_id === account.id);

					return accountData ? { ...account, metrics: accountData.interactions } : {
						...account, metrics: {
							sent_connections: 0,
							accepted_connections: 0,
							sent_messages: 0,
							sent_inmails: 0,
							replies: 0,
							views: 0
						}
					};
				});

				this.accounts = updatedAccounts;
				this.filteredAccounts = updatedAccounts;
				this.metricsObject = response.calculatedMetrics;
			}).catch(error => {
				this.showError(error.response.data.error);

				console.error(error);
			});
		},

		sortAccounts(data) {
			const isNumber = val => typeof val === "number";

			const getDataValue = (dataObject) => {
				if (data.name === "metrics") {
					if (!data.filters || data.filters.length === 0) {
						const { sent_connections, accepted_connections, views, sent_inmails, sent_messages, replies } = dataObject[data.name];

						return sent_connections + accepted_connections + views + sent_inmails + sent_messages + replies;
					}

					return data.filters.reduce((acc, filter) => acc + dataObject[data.name][filter.name], 0);
				} else {
					return isNumber(dataObject[data.name]) ? dataObject[data.name] : String(dataObject[data.name]).toLowerCase();
				}
			};

			if (this.sortedBy === data.name) {
				this.reversed = !this.reversed;
				this.filteredAccounts.reverse();
			} else {
				this.sortedBy = data.name;
				this.reversed = false;

				this.filteredAccounts.sort((a, b) => {
					const x = getDataValue(a);
					const y = getDataValue(b);

					return x < y ? -1 : x > y ? 1 : 0;
				});
			}
		},

		searchAccounts(searchData) {
			this.currentPage = 1;

			if (searchData === null) {
				this.filteredAccounts = this.accounts;
				return;
			}

			const searchTerm = searchData.trim().toLowerCase();

			this.filteredAccounts = this.accounts.filter(account => {
				const fullNameLower = account.full_name ? account.full_name.toLowerCase() : "";
				const emailLower = account.email ? account.email.toLowerCase() : "";
				const customerLower = account.customer_email ? account.customer_email.toLowerCase() : "";

				return (
					fullNameLower.includes(searchTerm) ||
					emailLower.includes(searchTerm) ||
					customerLower.includes(searchTerm) ||
					(account.customer_id && account.customer_id === searchTerm) ||
					(account.customer_email && account.customer_email === searchTerm) ||
					(account.chargebee_id && account.chargebee_id === searchTerm) ||
					(account.id && account.id === +searchTerm)
				);
			});
		},

		changePage(page) {
			this.currentPage = page;
		},

		getPagesLength(accountsLength, pageSize) {
			return Math.ceil(accountsLength / pageSize);
		},

		getFiltered(currentPage, pageSize) {
			return this.filteredAccounts.slice((currentPage - 1) * pageSize, currentPage * pageSize);
		},

		showError(errorObject) {
			this.toast.error({
				component: DefaultToast,
				props: {
					title: `${errorObject.type}!` || "UnknownError!",
					body: errorObject.message || "An error has occurred."
				}
			});
		}
	}
};
</script>
